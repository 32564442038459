import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '../service/common.service';
import { StorageService } from '../service/storage.service';


@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public secure: StorageService,private router: Router,private com:CommonService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.com.CustomerData) {
            return true;
        } else {
            this.router.navigate(['/'], {
                queryParams: {
                    return: state.url
                }
            });
            return false;
        }
    }
}