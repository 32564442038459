import { Component, Input, OnInit, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { toggleSidebar } from '../../service/toggleSidebar.service';
import { StorageService } from 'src/app/service/storage.service';
import { BGTemplateService } from 'src/app/service/bankgurantee';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() update = new EventEmitter<any>();

  screenWidth: number;
  message: string;
  public fixed: boolean = false;
  pbgArray: any; abgArray: any; cpbgArray: any; lcArray: any; localValue: any; customerID: string;
  sidebarToggleItems = false;
  showDropdown: string;
  LoggedInPersonDepartment: String;
  roleType: string;selectedItem:any;
  ngOnInit() {
    this.toggleSidebar.currentMessage.subscribe(message => this.message = message)
    this.LoggedInPersonDepartment = this.secure.secureStorage.getItem('department');
    if (this.secure.secureStorage.getItem("roleType") != null) {
      this.roleType = this.secure.secureStorage.getItem("roleType").trim();
    }

    if (this.localValue != 0) {
      this.customerID = this.commonService.superior_id;
    } else {
      this.customerID = this.commonService.CustomerId;

    }

    this.getListforBgType("PBG", '1');
    this.getListforBgType1("PBG", '2');
    this.getListforBgType2("PBG", '3');
    this.GoToABG(); this.GoToCPBG(); this.GoToLC();
  }

  
  selectItem(str){
    this.selectedItem=str;
  }
  expanded(event) {
    this.sidebarToggleItems = !this.sidebarToggleItems;
    if (this.sidebarToggleItems) {
      this.showDropdown = "collapse first-level";
    } else {
      this.showDropdown = "collapse first-level in";
    }
  }
  /* @Inject(Document) private doc: Document, */
  constructor(public secure: StorageService,private route: Router, private commonService: CommonService, private bgService: BGTemplateService, private toggleSidebar: toggleSidebar) { }

 // @HostListener("window:scroll", [])
 /*  onWindowScroll() {
    let num = this.doc.body.scrollTop;
    if (num > 50) {
      this.fixed = true;
    } else if (this.fixed && num < 5) {
      this.fixed = false;
    }
  } */
  GoToPBG() {
    //this.customerRemarkPbg = '';
    this.getListforBgType("PBG", '1');
    this.getListforBgType1("PBG", '2');
    this.getListforBgType2("PBG", '3');

  }
  GoToLC() {
   // this.customerRemarkPbg = '';

    this.getListforBgType("LC", '1');
    this.getListforBgType1("LC", '2');
    this.getListforBgType2("LC", '3');
  }
  GoToABG() {
    //this.customerRemarkPbg = '';

    this.getListforBgType("ABG", '1');
    this.getListforBgType1("ABG", '2');
    this.getListforBgType2("ABG", '3');
  }
  GoToCPBG() {
   // this.customerRemarkPbg = '';

    this.getListforBgType("CPBG", '1');
    this.getListforBgType1("CPBG", '2');
    this.getListforBgType2("CPBG", '3');
  }


  getListforBgType(bgTyepe, status) {
    this.pbgArray = [];
    this.bgService.getListforBgType(bgTyepe, status, this.customerID,"dashboard").subscribe((data) => {

      if (bgTyepe == "PBG") {
        this.pbgArray = data['data'];
        this.pbgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }
          if(element.pendingAt){
            element.pendingAt=element.pendingAt
          }else{
            element.pendingAt=element.pendingdate

          }
          var createdAt = new Date(element.pendingAt);
          //element.pendingAt = this.datepipe.transform(moment(createdAt.toISOString(), 'YYYY-MM-DD HH:mm:ss'), 'dd-MM-yyyy hh:mm:ss a');
        });
        //this.pendingCount = data['data'].length;
      }
      if (bgTyepe == "ABG") {
        this.abgArray = data['data'];
        this.abgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingABG = data['data'].length;
      }
      if (bgTyepe == "CPBG") {
        this.cpbgArray = data['data'];
        this.cpbgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingCPBG = data['data'].length;
      }
      if (bgTyepe == "LC") {
        this.lcArray = data['data'];
        this.lcArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingLC = data['data'].length;
      }


    })
  }

  getListforBgType1(bgTyepe, status) {
    this.pbgArray = [];
    this.bgService.getListforBgType(bgTyepe, status, this.customerID,"dashboard").subscribe((data) => {

      if (bgTyepe == "PBG") {
        this.pbgArray = data['data'];
        this.pbgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }
          if(element.pendingAt){
            element.pendingAt=element.pendingAt
          }else{
            element.pendingAt=element.pendingdate

          }
          var createdAt = new Date(element.pendingAt);
          //element.pendingAt = this.datepipe.transform(moment(createdAt.toISOString(), 'YYYY-MM-DD HH:mm:ss'), 'dd-MM-yyyy hh:mm:ss a');
        });
        //this.pendingCount = data['data'].length;
      }
      if (bgTyepe == "ABG") {
        this.abgArray = data['data'];
        this.abgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingABG = data['data'].length;
      }
      if (bgTyepe == "CPBG") {
        this.cpbgArray = data['data'];
        this.cpbgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingCPBG = data['data'].length;
      }
      if (bgTyepe == "LC") {
        this.lcArray = data['data'];
        this.lcArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingLC = data['data'].length;
      }


    })
  }

  getListforBgType2(bgTyepe, status) {
    this.pbgArray = [];
    this.bgService.getListforBgType(bgTyepe, status, this.customerID,"dashboard").subscribe((data) => {

      if (bgTyepe == "PBG") {
        this.pbgArray = data['data'];
        this.pbgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }
          if(element.pendingAt){
            element.pendingAt=element.pendingAt
          }else{
            element.pendingAt=element.pendingdate

          }
          var createdAt = new Date(element.pendingAt);
          //element.pendingAt = this.datepipe.transform(moment(createdAt.toISOString(), 'YYYY-MM-DD HH:mm:ss'), 'dd-MM-yyyy hh:mm:ss a');
        });
        //this.pendingCount = data['data'].length;
      }
      if (bgTyepe == "ABG") {
        this.abgArray = data['data'];
        this.abgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingABG = data['data'].length;
      }
      if (bgTyepe == "CPBG") {
        this.cpbgArray = data['data'];
        this.cpbgArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingCPBG = data['data'].length;
      }
      if (bgTyepe == "LC") {
        this.lcArray = data['data'];
        this.lcArray.forEach(element => {
          if (element.requisition_version_id == '' || element.requisition_version_id == null) {
            element.requisition_version_id = '';
          }

        });
        //this.PendingLC = data['data'].length;
      }


    })
  }


}
