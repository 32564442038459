import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';
import { httpFactory } from '@angular/http/src/http_module';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public zohoId = '';
  public showSpinner: boolean = false;
  public token: any;
  public CustomerName: any;
  public customerEmail: any;
  public CustomerId: any;
  public superior_id: any;
  public videoFlag: any;
  CustomerData: any;
  constructor(public secure: StorageService,private http: HttpClient) { }


  logout() {
    this.token = '';
    this.CustomerName = '';
    this.customerEmail = '';
    this.CustomerId = '';
    this.superior_id = '';
    this.CustomerData = '';
  }

  login(userData: { email: String, password: String }) {
    console.log('in login cust',userData);
    return this.http.post(environment.url + 'customer/login', userData);
  }
  //email, req.body.ClientName, req.body.contact_name,req.body.superior_id
  addCustomer(userData: { email: String, ClientName: String, contact_name: String, superior_id: String }) {
    return this.http.post(environment.url + 'customer/addCustomer', userData);
  }
  getAllGADStatus() {
    return this.http.get(environment.url + 'gad/getAllGADStatusCust', { headers: { 'x-access-token': this.token } });
  }

  getRegionMaster() {
    return this.http.get(environment.url + 'common/getRegionMasterCust', { headers: { 'x-access-token': this.token } });
  }

  getUserList(roleType, userId) {
    return this.http.get(environment.url + 'common/getUserListCust/' + roleType + '/' + userId);
  }

  getYear() {
    return this.http.get(environment.url + 'common/getYearCust');
  }
  changePassword(userData: { id: String, current_password: String, password: String }) {
    return this.http.post(environment.url + 'customer/changePassword/', userData, { headers: { 'x-access-token': this.token } });

  }
  forgotpassword(userData: { email: String }) {
    return this.http.post(environment.url + 'customer/forgotpassword/', userData);

  }
  changePasswordForgotPassword(userData: { email: String, password: String, newPassword: String, token: string }) {
    return this.http.post(environment.url + 'customer/changePasswordForgotPassword/', userData);

  }
  getAllImageForCrane() {
    return this.http.get(environment.url + "getAllImageForCrane");
  }

  updateVideoFlag(userData: {customerId: String, checkedValue: String}) {
    console.log('in service',userData);
    //return this.http.post(environment.url + 'customer/updateVideoFlag/' + userData);
    return this.http.post(environment.url + 'customer/updateVideoFlag/', userData,{ headers: { 'x-access-token': this.token } });
  }


  saveCustomerLog(userData: {customerID: any, customerName: String, customerEmail: String}) {
    return this.http.post(environment.url + 'customer/addCustomerLog', userData);
  }
}

