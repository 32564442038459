import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css'],
  
})
export class ModelComponent implements OnInit {

  @Input() title = `About`;
  
  constructor(public secure: StorageService,public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
