import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Router } from "@angular/router";
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../service/storage.service';


@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  
  constructor(public secure: StorageService) {
   
  }
  ngOnInit() {
   
  }
 
}

