import { Injectable } from '@angular/core';
import{HttpClient, HttpHeaders} from '@angular/common/http'
import {Http, Headers, RequestOptions} from '@angular/http';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  constructor(public secure: StorageService,private http: HttpClient) { }

  OfficeLogin() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin' :'*',
        'Access-Control-Allow-Methods': 'POST',
        'Accept':'text/html',
        'content-type':'text/html'
        
      })
    };
    
    return this.http.post("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauthorize&scope=openid%20profile%20offline_access%20https%3A%2F%2Foutlook.office.com%2Fcalendars.readwrite&response_type=code&client_id=5e07bb23-0efa-4879-8b93-a7f8252423f3",null, httpOptions);
  }

}
