import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { OfficeService } from 'src/app/service/office.service';
import { AuthService } from 'src/app/Auth/auth.service';
import { toggleSidebar } from '../../service/toggleSidebar.service';
import { StorageService } from 'src/app/service/storage.service';
@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {
  message: string;
  constructor(public secure: StorageService,private router: Router, private office: OfficeService,private authService: AuthService,public toggleSidebar: toggleSidebar) {

  }
  ngOnInit() {
    this.toggleSidebar.currentMessage.subscribe(message => this.message = message)
  }
  redirectToDash() { 
    
    this.router.navigateByUrl("/dashboard");
  }
  loginOffice() {
   
    this.authService.login();
  //  // this.document.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauthorize&scope=openid%20profile%20offline_access%20https%3A%2F%2Foutlook.office.com%2Fcalendars.readwrite&response_type=code&client_id=5e07bb23-0efa-4879-8b93-a7f8252423f3';
  //   this.office.OfficeLogin().subscribe(data => {
  

  //   });
  }
}
