import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonService } from '../service/common.service';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class BGTemplateService {

  public zohoId = '';

  constructor(public secure: StorageService,private http: HttpClient,private CommonService:CommonService) { 
    
  }

  getCustomerDashboard(customerId:String,page,product_id){
    return this.http.get(environment.url + 'bg/getCustomerDashboard/'+customerId+'/'+page+'/'+product_id,{ headers: { 'x-access-token': this.CommonService.token } });

  }
  getRecentOrderForCustomer(customerId:String,page,product_id){
    return this.http.get(environment.url + 'bg/getRecentOrderForCustomer/'+customerId+'/'+page+'/'+product_id,{ headers: { 'x-access-token': this.CommonService.token } });

  }

  uploadTemplateforBg(data:{attachment:String,requisition_id:String,customer_remark:String,customer_id:String,status_flag:String,zohoid:String,bgType:String}){
    return this.http.post(environment.url + 'bg/addCustRequisionversionCust/',data,{ headers: { 'x-access-token': this.CommonService.token } });

  }
  ///customerApproval/:flag/:requisition_id
  customerApproval(flag,version_id,requisition_id,opportunityno,customer_id,bgType){
    return this.http.get(environment.url + 'bg/customerApprovalCust/'+flag+'/'+requisition_id+'/'+version_id+'/'+opportunityno+'/'+customer_id+'/'+bgType,{ headers: { 'x-access-token': this.CommonService.token } });

  }
  getBGTemplateList(bgformat) {
    return this.http.get(environment.url + 'bg/getFiTemplatesForBGCust/'+bgformat,{ headers: { 'x-access-token': this.CommonService.token } });
  }

  getListforBgType(bgType,status,customerID,page){
   
    return this.http.get(environment.url + 'bg/getCustListforBgTypeCust/'+bgType+'/'+status+'/'+customerID+'/'+page,{ headers: { 'x-access-token': this.CommonService.token } });

  }
  getHistoryForRequisitionID(requisition_id) {
    return this.http.get(environment.url + 'bg/getHistoryForRequisitionIDCust/' + requisition_id,{ headers: { 'x-access-token': this.CommonService.token } });

  }

  getStatusForCustomer(userId,page){
    //console.log("Product id======",userId);
    return this.http.get(environment.url + 'bg/getStatusForCustomerCust/'+userId+'/'+page,{ headers: { 'x-access-token': this.CommonService.token } });

  }

  getProductStatus(zohoid){
    return this.http.get(environment.url + 'bg/getProductStatusCust/'+zohoid,{ headers: { 'x-access-token': this.CommonService.token } });

  }

  getCRStatus(zohoid){
    return this.http.get(environment.url + 'bg/getCRStatusCust/'+zohoid,{ headers: { 'x-access-token': this.CommonService.token } });

  }
  
  //===============BY ashu===================

  getProjectOfCustomer(customerId,page,product_id){
    return this.http.get(environment.url + 'customerDashboard/getProjectOfCustomer/'+customerId+'/'+page+'/'+product_id,{ headers: { 'x-access-token': this.CommonService.token } });
  }  
  //===============BY ashu===================

  
  getVideoMaster(){
    return this.http.get(environment.url + 'videoMaster/getVideoList');
  } 
}
