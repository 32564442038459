import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import {  Router}  from '@angular/router';
import {  AuthService}  from '../../Auth/auth.service';
import {  toggleSidebar}  from '../../service/toggleSidebar.service';
import { CommonService } from 'src/app/service/common.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  implements OnInit {
  roleType: string;
  user : string;
  userEmail : string;
  Ltype = [];
  arraylist: any;  
  sidebarToggleItems: boolean;
  showDropdown: string;
  constructor(public secure: StorageService,private router: Router, private auth: AuthService,private commonService:CommonService, private toggleSidebar: toggleSidebar) { }
  ngOnInit() {
    this.userEmail = this.commonService.customerEmail;
  }
  openSidebar() {  
    this.sidebarToggleItems = !this.sidebarToggleItems; 
    console.log(this.sidebarToggleItems)
    if (this.sidebarToggleItems) {
      this.showDropdown = "active";
    }
    else {
      this.showDropdown = "active in";
    }
    this.toggleSidebar.changeMessage(this.showDropdown)
  }
  logout() {
    this.secure.secureStorage.clear();
    this.auth.logout();
    this.deleteAllCookies();
    console.log('cookies deleted');
    this.router.navigateByUrl('/',
      {
        replaceUrl: true
      }
    );
  }


  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
}