import { DataTableModule } from 'angular2-datatable';
import { Component, OnInit } from '@angular/core';
import { Subscription, from } from 'rxjs';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import { ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';

import { Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../service/common.service';
import { StorageService } from '../service/storage.service';


@Component({
  selector: 'app-change_password',
  templateUrl: './change_password.component.html',
  styleUrls: ['./change_password.component.scss'],

})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;
  responseMessage: any;



  constructor(public secure: StorageService,public com: CommonService, private spinner: NgxSpinnerService, public toastr: ToastrManager, private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
   
    this.form = this.fb.group(
      {
        'id': new FormControl(''),
        'current_password': new FormControl('', Validators.required),
        'password': new FormControl('', [Validators.required]),
      }
    );
  }
  showSuccess(msg) {
    this.toastr.successToastr(msg, 'Success!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });
  }
  showError(msg) {
    this.toastr.errorToastr(msg, 'Error!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });
  }
  onSubmit() {
    
    this.com.changePassword({
//      'id': this.secure.secureStorage.getItem("customerId"),
       'id': this.com.CustomerId,
      'current_password': this.form.value.current_password,
      'password': this.form.value.password,
    }).subscribe(
      data => {
        if (data['error']) {
          this.responseMessage = data["message"];
          this.showError(this.responseMessage);
        }
        else {
          this.form.reset();
          this.responseMessage = data["message"];
          this.showSuccess(this.responseMessage);
          this.secure.secureStorage.clear();
          setTimeout(() => {
            this.router.navigateByUrl('/',
            {
              replaceUrl: true
            }
          );
          }, 2000);     
        }
      },
    )
  }

  cancel() {
    this.form.reset();
  }

}
