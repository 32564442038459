import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routing';
import { FullLayoutComponent } from './common/full-layout/full-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './Auth/auth.service';
import { HttpService } from './shared/http.service';
import { SignInComponent } from './sign-in/sign-in.component';
import { HeaderComponent } from './common/header/header.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { ModelComponent } from './common/model/model.component';
     
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ng6-toastr-notifications';
import { HttpModule } from '@angular/http';
import { Ng4LoadingSpinnerService, Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { toggleSidebar } from './service/toggleSidebar.service';
import { ChangePasswordComponent } from './change-password/change_password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot_password.component';
import { AuthGuardService } from './common/AuthGuardService';
import { PageNotFoundComponent } from './PageNotFound/pagenotfound.component';
import { TokenInterceptorService } from './service/tokenInterceptorService.service';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ModelComponent,
    SignInComponent,
    HeaderComponent,
    SidebarComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    PageNotFoundComponent,

  ],

  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule, HttpModule,
    ReactiveFormsModule, BrowserModule, ToastrModule.forRoot(), Ng4LoadingSpinnerModule.forRoot(),
    NgxSpinnerModule,

    // NgbModule,
    // NgbActiveModal,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{useHash: true})
  ],
  providers: [AuthService, HttpService, Ng4LoadingSpinnerService, toggleSidebar,AuthGuardService,{
    provide: HTTP_INTERCEPTORS, 
    useClass: TokenInterceptorService, 
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
