import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, take, switchMap, retry } from "rxjs/operators";
import { AuthService } from '../Auth/auth.service';
import { StorageService } from './storage.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(public secure: StorageService, private _authService: AuthService, public toastr: ToastrManager) {

    }
    showSuccess(msg) {
        this.toastr.errorToastr(msg, 'Error!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 5000 });
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

          var userId = CryptoJS.AES.encrypt(JSON.stringify(`${this._authService.getUserId()}`), "AFT@123456").toString();
        var userType = CryptoJS.AES.encrypt(JSON.stringify("Customer"), "AFT@123456").toString();

        request = request.clone({
            setHeaders: {
                'x-access-token': `${this._authService.getToken()}`,
                'userId': userId,
                'type':userType
            },

        });
        // All HTTP requests are going to go through this method
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                    }
                    if (error.status === 401) {
                        this.toastr.errorToastr("Something went wrong,Please try again..!!", 'Error!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });

                    }
                    if (error.status === 404) {
                        this.toastr.errorToastr("Something went wrong,Please try again..!!", 'Error!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });

                    }

                    if (error.status === 500) {
                        errorMessage = `Error Status: 500 \nMessage: ${error.error.message}`;
                        this.showSuccess(errorMessage);
                    }

                    if (error.status === 429) {
                        errorMessage = `You have exceeded the 200 requests in 1 minutes limit!`;
                        
                        this.showSuccess(errorMessage);

                    }

                    //console.log("=======TOKN++++++++", errorMessage);
                    return throwError(errorMessage);
                })
            )
    }
}