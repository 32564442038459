import { Injectable } from '@angular/core';
import {  NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as hello from 'hellojs/dist/hello.all.js';
import { Configs } from '../shared/config.service';

import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import * as MicrosoftGraphClient from "@microsoft/microsoft-graph-client";
import { HttpService } from '../shared/http.service';
import 'rxjs/add/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { StorageService } from '../service/storage.service';


@Injectable()
export class AuthService {
  url = 'https://graph.microsoft.com/v1.0';
  file = 'demo.xlsx';
  table = 'Table1';

  token: any;
  CustomerId: any;
  
  constructor(public secure: StorageService,
    private zone: NgZone,
    private router: Router,private httpService:HttpService
  ) { }
  getToken() {
    return this.secure.secureStorage.getItem("token");
  }
  getUserId(){
    return this.secure.secureStorage.getItem("customer_id");
  }
  
  initAuth() {
    hello.init({
      msft: {
        oauth: {
          version: 2,
          auth: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
        },
        scope_delim: ' ',
        form: false
      }
    });
    
    hello.init({
      msft: Configs.appId
    }, {
      redirect_uri: "http://localhost:4205/customer"
    });
  }

  login() {
    hello('msft').login({ scope: Configs.scope }).then(
      (data) => {
        
        this.zone.run(() => {
          this.router.navigate(['/customer']);
        });
      },
      e => console.error(e)
    );
  }

  logout() {
    hello('msft').logout().then(
      () => window.location.href = '/',
      e => console.error(e.error.message)
    );
  }



  getClient(): MicrosoftGraphClient.Client
  {
    var client = MicrosoftGraphClient.Client.init({
      authProvider: (done) => {
          done(null, this.httpService.getAccessToken()); //first parameter takes an error if you can't get an access token
      }
    });
    return client;
  }

  getMe(): Observable<MicrosoftGraph.User>
  {
    var client = this.getClient();
    return Observable.fromPromise(client
    .api('me')
    .select("displayName, mail, userPrincipalName")
    .get()
    .then ((res => {
      return res;
    } ) )
    );
  }





}
