import { DataTableModule } from 'angular2-datatable';
import { Component, OnInit, Inject } from '@angular/core';
import { Subscription, from } from 'rxjs';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import { ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';
import { Http, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../service/common.service';
import { StorageService } from '../service/storage.service';


@Component({
  selector: 'app-forgot_password',
  templateUrl: './forgot_password.component.html',
  styleUrls: ['./forgot_password.component.scss'],

})
export class ForgotPasswordComponent implements OnInit {
  
  public form: FormGroup;
  responseMessage: any;
  email: any;
  token: any;
  btnFlag: boolean=true;
 

 
  constructor(public secure: StorageService,@Inject(DOCUMENT) private document: any,private route: ActivatedRoute,public commonService: CommonService, private spinner: NgxSpinnerService, public toastr: ToastrManager, private router: Router, private fb: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.email = params['email']
      this.token = params['token']

    });
    this.form = this.fb.group(
      {
      
        'current_password': new FormControl('', Validators.required),
        'password': new FormControl('', [Validators.required]),
      }
    );

    
   
 
  }
  checkPass(){
    this.btnFlag=true;
   
    if((this.form.value.current_password==this.form.value.password )&&this.form.value.current_password!=""){
      this.btnFlag=false;

    }else{
      this.btnFlag=true;
    }
  }
  showSuccess(msg) {
    this.toastr.successToastr(msg, 'Success!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });
  }
  showError(msg) {
    this.toastr.errorToastr(msg, 'Error!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });
  }
  onSubmit() {
    console
 if(this.form.value.current_password&&this.form.value.password){
  this.commonService.changePasswordForgotPassword({
    'email': this.email,
    'password': this.form.value.current_password,
    'newPassword': this.form.value.password,
    'token':this.token
   
  }).subscribe(
    data => {
      if (data['error']) {
        this.responseMessage = data["message"];
        this.showError(this.responseMessage);
      }
      else {
        this.btnFlag=true;
        this.form.reset();
        this.responseMessage = data["message"];

        this.showSuccess(this.responseMessage);
        setTimeout(() => {
          this.document.location.href = 'https://emsight.emech.in';
      
        }, 2000);
        
      }
    },
  )
  }
    
  
  }

cancel()
{
  this.form.reset();
}

}
