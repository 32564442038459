import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class toggleSidebar {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor(public secure: StorageService,) { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }
}