import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './common/full-layout/full-layout.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ChangePasswordComponent } from './change-password/change_password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot_password.component';
import { AuthGuardService } from './common/AuthGuardService';
import { PageNotFoundComponent } from './PageNotFound/pagenotfound.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: SignInComponent,
  },
  {
    path: 'forgotpassword/:email/:token',
    component: ForgotPasswordComponent,
  },

  {
    path: 'customer',
    component: FullLayoutComponent,
    children: [
      {
        path: 'changepassword',
        component: ChangePasswordComponent,
        canActivate: [AuthGuardService]
      },
      {

        path: '',
        loadChildren: './Customer-Dashboard/customer.module#CustomerModule',
        canActivate: [AuthGuardService]
      },
      
      
      

      {
        path: 'bg',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './bank-gurantee/bg.module#BGModule',
            

          },
        ]
      },
      {
        path: 'trackorder/:zohoid/:product_id/:img',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './track-order/track-order.module#TrackModule',

          },
        ]
      },
      {
        path: 'myOrder',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './my-order/my-order.module#MyorderModule',

          },
        ]
      },
      {
        path: 'ordersummry',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './orderSummary/order-summary.module#OrderSummaryModule',

          },
        ]
      },
      {
        path: 'payment',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './Payment/payment.module#PaymentModule',
          },
        ]
      },
      {
        path: 'nps',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './NPS/nps.module#NPSModule',
          },
        ]
      },
      {
        path: 'kom',
        canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './KOM/kom.module#KOMModule'
          },
        ]
      },
      {
        path: 'inspection'
        , canActivate: [AuthGuardService],
        children: [
          {

            path: '',
            loadChildren: './Inspection/inspection.module#InspectionModule'
          },
        ]
      },
      {
        path: 'project', canActivate: [AuthGuardService],

        children: [
          {

            path: '',
            loadChildren: './Project/project.module#ProjectModule'
          },
        ]
      },
      {
        path: 'gad', canActivate: [AuthGuardService],

        children: [
          {

            path: '',
            loadChildren: './GAD/gad.module#GADModule'
          },
        ]
      },

      {

        path: 'po', canActivate: [AuthGuardService],
        loadChildren: './PO/po.module#POModule'

      },
      {

        path: 'preMobilization',
        loadChildren: './Pre-Moblization/PreMobilization.module#PreMobilizationModule'
        , canActivate: [AuthGuardService]
      }, 
      
      {
        path: 'osmManual',
        loadChildren: './OSM_Manual/OSM_Manual.module#OSMmanualModule', canActivate: [AuthGuardService]
      },

    ]
  },
  {
    path: '**',
    component:PageNotFoundComponent
  }



]
