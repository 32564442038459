import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OfficeService } from '../service/office.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../Auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../service/common.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '../service/storage.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loginform: FormGroup;
  submitted = false;
  email: any;
  noSpecial: RegExp = /^[^<>@%&$*()^#?!0-9]+$/;
  responseMessage: any;
  return: any;

  constructor(public secure: StorageService,public commonService: CommonService, private ng4LoadingSpinnerService: Ng4LoadingSpinnerService, private fb: FormBuilder, private route: ActivatedRoute,
    private router: Router, private office: OfficeService, private authService: AuthService, private com: CommonService, public toastr: ToastrManager,
    private zone: NgZone, private spinner: NgxSpinnerService) {

  }
  ngOnInit() {
    this.loginform = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]

    });

    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/customer');
  }

  onSubmit() {
    this.secure.secureStorage.clear();
    this.submitted = true;
    var email = this.loginform.value.email;
    var password = this.loginform.value.password;
    // this.secure.secureStorage.setItem("custName", this.loginform.value.email);
    // this.router.navigateByUrl("/customer");

    this.login();

  }


  login() {
    var ciphertext1 = CryptoJS.AES.encrypt(JSON.stringify(this.loginform.controls.email.value), "AFT@123456").toString();
    var ciphertext2 = CryptoJS.AES.encrypt(JSON.stringify(this.loginform.controls.password.value), "AFT@123456").toString();

    this.com.login({ email: ciphertext1, password:ciphertext2 }).subscribe((data1) => {
      this.secure.secureStorage.clear();
      var dataen = CryptoJS.AES.decrypt(data1, "AFT@123456");
      var data = JSON.parse(dataen.toString(CryptoJS.enc.Utf8));
      if (!data['error']) {
        // console.log("DATA==============",data);
        //  this.secure.secureStorage.setItem("customerData", data['data']);
        //  this.secure.secureStorage.setItem("customerId", data['data'].customer_id);
        //  this.secure.secureStorage.setItem("customerEmail", data['data'].email);
        //  this.secure.secureStorage.setItem("token", data['token']);
        //  this.secure.secureStorage.setItem("customer_name", data['data'].customer_name);
        //  this.secure.secureStorage.setItem("superior_id", data['superior_id']);

        var bytes = CryptoJS.AES.decrypt(data['data'], "AFT@123456");
        var UserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var bytes1 = CryptoJS.AES.decrypt(data["token"], "AFT@123456");
        var token = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));



        this.com.CustomerId = UserData.customer_id;
        this.com.superior_id = data['superior_id'];
        this.com.CustomerName = UserData.customer_name;
        this.com.token = token;
        this.com.customerEmail = UserData.email;
        this.com.CustomerData = UserData;
        //this.com.videoFlag = UserData.video_flag;
        this.secure.secureStorage.setItem('superior_id',UserData.superior_id)
        this.secure.secureStorage.setItem('superior_id',UserData.superior_id)
        this.secure.secureStorage.setItem('token',token)
        this.secure.secureStorage.setItem('customer_id',this.com.CustomerId)
        this.secure.secureStorage.setItem('video_flag',UserData.video_flag)
        this.secure.secureStorage.setItem('UserData',UserData)

        console.log('UserDatavideoFlag',UserData.video_flag);
        if (!data['userExist']) {
          if (data['superior_id'] != 0) {

            this.com.addCustomer({ email: this.loginform.controls.email.value, ClientName: UserData.customer_name, contact_name: UserData.contact_name, superior_id: data['superior_id'] }).subscribe((data1) => {

              // this.secure.secureStorage.setItem("customerId", data1['data'].customer_id);
              // this.secure.secureStorage.setItem("customerEmail", data1['data'].email);
              // this.secure.secureStorage.setItem("cstomer_name", data1['data'].customer_name);
              // this.secure.secureStorage.setItem("superior_id", data1['data'].superior_id);
              // this.secure.secureStorage.setItem("token", data1['token']);

              this.com.CustomerId = data1['data'].customer_id;
              this.com.customerEmail = data1['data'].email;
              this.com.CustomerName = data1['data'].customer_name;
              this.com.superior_id = data1['data'].superior_id;
              this.com.token = data1['token'];
              this.secure.secureStorage.setItem('superior_id',data1['data'].superior_id)
              this.secure.secureStorage.setItem('customer_id',data1['data'].customer_id)
              //console.log("DATA1==============",data1);

              this.zone.run(() => {
                this.router.navigateByUrl(this.return);
              });
            });
          } else {
            this.zone.run(() => {
              this.router.navigateByUrl(this.return);
            });
          }
        } else {
          this.zone.run(() => {
            this.router.navigateByUrl(this.return);
          });
        }
      } else {
        this.showError("Invalid Credentials...!!!");
      }
    })
  }
  forgotpassword() {

    this.commonService.forgotpassword({
      'email': this.email,
    }).subscribe(
      data => {
        if (data['error']) {
          this.responseMessage = data["message"];
          this.showError(this.responseMessage);
        }
        else {
          this.email = "";
          this.responseMessage = data["message"];
          this.showSuccess(this.responseMessage);

        }
      },
    )
  }
  showSuccess(msg) {
    this.toastr.successToastr(msg, 'Success!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });
  }
  showError(msg) {
    this.toastr.errorToastr(msg, 'Error!', { position: 'top-center', animate: 'slideFromBottom', toastTimeout: 2000 });
  }
}
