import { Component, OnInit } from '@angular/core';
import { AuthService } from './Auth/auth.service';
import { StorageService } from './service/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',    
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(public secure: StorageService,private authService: AuthService) { }

  title = 'EmSight';
  ngOnInit() {
    this.authService.initAuth();
  }
}
